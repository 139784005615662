// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nodi-marinari-a-occhio-js": () => import("/opt/build/repo/src/pages/nodi-marinari-a-occhio.js" /* webpackChunkName: "component---src-pages-nodi-marinari-a-occhio-js" */),
  "component---src-pages-nodi-marinari-di-accorciamento-e-regolazione-js": () => import("/opt/build/repo/src/pages/nodi-marinari-di-accorciamento-e-regolazione.js" /* webpackChunkName: "component---src-pages-nodi-marinari-di-accorciamento-e-regolazione-js" */),
  "component---src-pages-nodi-marinari-di-arresto-js": () => import("/opt/build/repo/src/pages/nodi-marinari-di-arresto.js" /* webpackChunkName: "component---src-pages-nodi-marinari-di-arresto-js" */),
  "component---src-pages-nodi-marinari-di-avvolgimento-js": () => import("/opt/build/repo/src/pages/nodi-marinari-di-avvolgimento.js" /* webpackChunkName: "component---src-pages-nodi-marinari-di-avvolgimento-js" */),
  "component---src-pages-nodi-marinari-di-giunzione-js": () => import("/opt/build/repo/src/pages/nodi-marinari-di-giunzione.js" /* webpackChunkName: "component---src-pages-nodi-marinari-di-giunzione-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

